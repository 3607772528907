import baseLoadable, { DefaultComponent, LoadableComponent, Options } from '@loadable/component';
import { DontHydrate } from '@loveholidays/design-system';
import hoistNonReactStatics from 'hoist-non-react-statics';
import React, { createContext, useContext, ElementType } from 'react';
import { SxStyleProp } from 'theme-ui';

import { PreLoadedLoadableOptions, preLoadedLoadable } from './preLoadedLoadable';

// @TODO: move this to loadable-component.d.ts
interface LoadableObj<T> {
  chunkName: (props?: T) => string;
}

interface LazyHydratedOptions<T> {
  wrapper?: ElementType;
  wrapperStyles?: SxStyleProp;
  preload?: boolean;
  loadableOptions?: PreLoadedLoadableOptions<T>;
}

export const DontHydrateChunksContext = createContext(new Set<string>());

/**
 * This function extends the 'loadable' function from '@loadable/component' to keep track of chunks
 * we do not want downloaded on the client after being rendered server side as it does not require
 * hydration.
 */
export function dontHydrateLoadable<T extends JSX.IntrinsicAttributes>(
  loadFn: (props: T) => Promise<DefaultComponent<T>>,
  options: Options<T> & LazyHydratedOptions<T> = {},
): LoadableComponent<T> {
  const { wrapper, wrapperStyles, preload = false, loadableOptions } = options;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const chunkStore = useContext(DontHydrateChunksContext);
  const loadableObj: LoadableObj<T> = loadFn as unknown as LoadableObj<T>;

  const Loadable = preload
    ? preLoadedLoadable(loadFn, loadableOptions)
    : baseLoadable(loadFn, loadableOptions);

  // Push chunk name to excluded chunks on server side
  if (typeof window === 'undefined') {
    chunkStore.add(loadableObj.chunkName());
  }

  // @ts-ignore
  const WithLazyHydration: LoadableComponent<T> = (props: T) =>
    (
      <DontHydrate
        as={wrapper}
        sx={wrapperStyles}
      >
        <Loadable {...props} />
      </DontHydrate>
    ) as any;

  hoistNonReactStatics(WithLazyHydration, Loadable);

  return WithLazyHydration;
}
