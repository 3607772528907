import baseLoadable, { OptionsWithoutResolver } from '@loadable/component';
import { DontHydrate } from '@loveholidays/design-system';
import hoistNonReactStatics from 'hoist-non-react-statics';
import React, { ElementType, useContext } from 'react';
import { SxStyleProp } from 'theme-ui';

import { DontHydrateChunksContext } from './DontHydrateLoadable';

interface Options<T> {
  wrapper?: ElementType;
  wrapperStyles?: SxStyleProp;
  className?: string;
  loadableOptions?: OptionsWithoutResolver<T>;
}

const lib = <Props, Module>(
  loadFn: (props: Props) => Promise<Module>,
  options: Options<Props> = {},
) => {
  const { wrapper = 'span', wrapperStyles, className, loadableOptions } = options;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const chunkStore = useContext(DontHydrateChunksContext);

  // Push chunk name to excluded chunks on server side
  if (typeof window === 'undefined') {
    chunkStore.add((loadFn as any).chunkName());
  }

  const Loadable = baseLoadable.lib(loadFn, loadableOptions);

  // @ts-ignore
  const WithLazyHydration: LoadableComponent<T> = (props: T) =>
    (
      <DontHydrate
        as={wrapper}
        sx={wrapperStyles}
        className={className}
      >
        <Loadable {...props} />
      </DontHydrate>
    ) as any;

  hoistNonReactStatics(WithLazyHydration, Loadable);

  return WithLazyHydration;
};

export const loadable = {
  lib,
};
